import React from 'react'
import './CreateCard.css'

class CreateCard extends React.Component {

    constructor(props) {
        super(props)
    }

    state = {
        input: '',
        descriptionText: '',
        descriptionButtonText: ''
    }

    componentDidMount() {
        
    }


    handleInput = (event) => {
        event.persist()
        this.setState({
            input: event.target.value
        })
    }

    handleNewCard = (event) => {
        event.preventDefault()
        this.props.createNewCard(this.state.input)
    }
    SetText(dtext, buttonText) {
        this.setState({ descriptionText: dtext }, () => { console.log(this.state.descriptionText) });
        this.setState({ descriptionButtonText: buttonText });
    }

    render() {
        return (
            <form onSubmit={this.handleNewCard} className="new-card-form">
                <h4 value={this.state.descriptionText}></h4>
                <input onChange={this.handleInput} className="new-card-input" type="text" value={this.state.input} />
                <input className="new-card-input" type="submit" value={this.state.descriptionButtonText} />
            </form>
        )
    }
}

export default CreateCard;