import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';

function CollapsibleExample() {
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/home">RB Tools</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="topnav">
                        {/*<Nav.Link href="/HaushaltsfuehrungsSchaden">test</Nav.Link>*/}
                        {/*<Nav.Link href="pricing">Pricing</Nav.Link>*/}
                        <NavDropdown title="Programme" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="/action/3.1">HaushaltsfuehrungsSchaden</NavDropdown.Item>
                            {/*<NavDropdown.Item href="/action/3.2">Rechnung Erstellen</NavDropdown.Item>*/}
                            {/*<NavDropdown.Item href="/action/3.3">Something</NavDropdown.Item>*/}
                            {/*<NavDropdown.Divider />*/}
                            {/*<NavDropdown.Item href="/action/3.4">Separated link</NavDropdown.Item>*/}
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        {/*<Nav.Link href="#deets">More deets</Nav.Link>*/}
                        {/*<Nav.Link eventKey={2} href="#memes">*/}
                        {/*    Dank memes*/}
                        {/*</Nav.Link>*/}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CollapsibleExample;